<template>
  <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>

export default {
    components: {
      
    }
}
</script>

<style>
.W60 {
    width:60px !important;
} 
.W80 {
    width:80px !important;
} 
.W100 {
    width:100px !important;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-break:keep-all;
} 
.W120 {
    width:120px !important;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-break:keep-all;
}
.W130 {
    width:130px !important;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-break:keep-all;
} 
.W140 {
    width:140px !important;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-break:keep-all;
} 
.W150 {
    width:150px !important;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    word-break:keep-all;
}
.W160 {
    width:160px !important;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    word-break:keep-all;
}
</style>